.header {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 104px;
    @include gp;

    @include mq(992) {
        max-height: 92vh;
        padding-bottom: 60px;
    }

    @media only screen and (orientation: landscape) and (max-width: 992px) {
        min-height: 500px;
    }

    &__h1 {
        font-size: 110px * 0.8;
        line-height: 115px * 0.8;
        margin: 0;
        padding: 0;
        font-weight: normal;
        margin-bottom: 50px;

        @include mq(1919) {
            font-size: 80px * 0.8;
            line-height: 85px * 0.8;
        }

        @include mq(1024) {
            font-size: 70px * 0.8;
            line-height: 75px * 0.8;
        }

        @include mq(992) {
            font-size: 60px;
            line-height: 60px;
            // margin-bottom: 50px;
        }

        @include mq(576) {
            font-size: 44px * 0.8;
            line-height: 44px * 0.8;
            // margin-bottom: 50px;
        }
    }

    &__desc {
        opacity: 0.8;	
        color: #FFFFFF;	
        font-size: 20px;	
        font-weight: 500;	
        letter-spacing: 1px;	
        line-height: 38px;
        margin-bottom: 40px;

        @include mq(1919) {
            font-size: 16px;
            line-height: 24px;
        }

        @include mq(992) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 80px;
        }

        @include mq(576) {
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 80px;
        }
    }
}