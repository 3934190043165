.logo {
    padding: 40px 40px;

    &__img {
        width: 81px;
        height: auto;
    }

    @include mq(992) {
        padding: 40px 20px;
    }
}