.footer {
    background-color: $main_blue;
    display: flex;
    flex-direction: column;
    // padding-top: 106px;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
    @include gp;

    @include mq(1440) {
        padding-top: 0px;
    }

    @include mq(992) {
        padding-top: 45px;
        padding-left: 40px;
    }

    @include mq(576) {
        padding-left: 0;
        padding-top: 20px;
    }

    &__head {
        font-size: 80px;	
        font-weight: 500;	
        line-height: 115px;

        @include mq(1440) {
            font-size: 70px;	
            line-height: 115px;
        }

        @include mq(1024) {
            font-size: 60px;	
            line-height: 115px;
        }

        @include mq(992) {
            font-size: 40px;	
            line-height: 44px;
        }
    }

    &__subhead {
        opacity: 0.7;
        font-size: 16px;	
        font-weight: 500;	
        line-height: 26px;

        @include mq(992) {
            font-size: 12px;
            line-height: 24px;
            margin-top: 25px;
        }

        @include mq(576) {
            margin-top: 15px;
         }
    }

    &__or-write {
        font-size: 16px;	
        font-weight: 500;	
        line-height: 26px;
        margin-top: 50px;
        opacity: 0.7;
        margin-bottom: 30px;

        @include mq(1366) {
            margin-bottom: 10px;
            margin-top: 30px;
        }

        @include mq(992) {
            font-size: 12px;
            line-height: 24px;
            margin-top: 40px;
            margin-bottom: 10px;
        }

        @include mq(576) {
            margin-top: 20px;
         }
    }

    &__socials {
        display: flex;

        @include mq(576) {
            flex-direction: column;
        }
    }

    &__social {
        display: flex;
        align-items: center;

        &-img {
            width: 26px;
            height: 22px;
            margin-right: 17px;

            @include mq(1440) {
                width: 24px;
                height: 20px;
            }

            @include mq(576) {
                width: 18px;
                height: 14px;
                margin-right: 8px;
             }
        }

        &-text {
            font-size: 23px;	
            font-weight: 700;	
            letter-spacing: 2.88px;	
            line-height: 27px;
            color: #ffffff;

            @include mq(1440) {
                font-size: 20px;
            }

            @include mq(576) {
                font-size: 14px;
            }
        }

        &:hover {
            text-decoration: none;
        }

        &_email {
            margin-right: 70px;

            @include mq(576) {
                margin-right: 0;
                margin-bottom: 10px;
            }

            @include mq(576) {
                margin-bottom: 0px;
            }
        }


    }

    &__bottom {
        display: flex;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 14px;	
        font-weight: 500;	
        letter-spacing: 1px;	
        line-height: 16px;
        align-items: center;
        justify-content: space-between;
        padding-right: 40px;
        padding-bottom: 10px;

        @include mq(992) {
            flex-wrap: wrap;
            padding-right: 0;
            padding: 0 10px;
        }

    }

    &__copyright,&__terms,&__else {
        padding: 0 37px;
        font-size: 14px;	
        font-weight: 500;	
        letter-spacing: 1px;	
        line-height: 16px;
        opacity: 0.7;

        @include mq(1366) {
            font-size: 12px;
        }

        @include mq(1024) {
            padding: 0 15px;
        }

        @include mq(992) {
            font-size: 10px;
            padding: 0;
        }


    }

    &__copyright {
        &_mobile {
            flex-basis: 100%;
            padding-left: 10px;
            margin-left: 30px;

            a {
                color: rgba(#fff,1);
            }
            

            @include mq(768) {
                margin-left: 0;
                padding-left: 0;
            }
        }

        a {
            transition: all .3s ease-in-out;
            &:hover {
                text-decoration: none;
            }
        }

        &:hover {
            opacity: 1;
            color: rgba(#fff,0.7);
            a {
                color: #fff;
            } 
        }
    }

    &__terms {	
        color: #ffffff;

        &:hover {
            text-decoration: none;
            color: #ffffff;
            opacity: 1;
        }

        @include mq(992) {
            margin-left: 40px;
            margin-right: 40px;
        }

        
        @include mq(768) {
            margin-left: 0;
        }

        @include mq(576) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    &__links {
        display: flex;
        a {
            color: #ffffff;
        }

        @include mq(992) {
            flex-basis: 50%;
        }

        @include mq(576) {
            margin-bottom: 10px;
            flex-basis: 70%;
        }
    }

    &__bottom-socials {
        display: flex;

        @include mq(992) {
            flex-basis: 50%;
        }

        @include mq(576) {
            padding-right: 40px;
            flex-basis: 30%;
        }
    }

    &__bottom-social {
        display: flex;
        margin-right: 20px;
        color: rgba(#ffffff,0.5);
        align-items: center;
        transition: all .3s ease-in-out;

        @include mq(576) {
            margin-right: 0;
        }

        svg {
            width: 25px;
            height: 25px;
            fill: rgba(#ffffff,0.5);
            margin-right: 10px;
            transition: all .3s ease-in-out;

            @include mq(576) {
                width: 20px;
                height: 20px;
            }
        }

        &:hover {
            color: rgba(#ffffff,1);
            text-decoration: none;

            svg {
                fill: #fff;
            }
        }

        &-text {
            @include mq(576) {
                display: none;
            }
        }
    }
}