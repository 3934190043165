.text-blue {
    color: $main_blue;
}

.container {
    max-width: 1440px;

    @include mq(1600) {
        max-width: 1260px;
    }

    @include mq(1440) {
        max-width: 1100px;
    }

    @include mq(1024) {
        max-width: 800px;
    }
}

.fat {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;

    &_nomobile {
        @include mq(992) {
            display: none;
        }

        &_xs {
            @include mq(576) {
                display: none;
            }
        }
    }

}

.no-mobile {
    @include mq(992) {
        display: none;
    }
}

.no-desktop {
    @media only screen and (min-width: 993px) {
        display: none;
    }
}