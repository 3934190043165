.person-modal {
    font-family: Raleway, sans-serif;
    &__title {
        font-size: 46px;	
        font-weight: 700;	
        line-height: 60px;
        text-transform: uppercase;

        @include mq(1440) {
            font-size: 38px;
            line-height: 50px;
        }

        @include mq(1366) {
            font-size: 32px;
            line-height: 46px;
        }

        @include mq(1024) {
            font-size: 32px;
            line-height: 46px;
        }

        @include mq(992) {
            font-size: 26px;
            line-height: 34px;
        }
    }

    &__post {
        font-size: 18px;	
        font-weight: 600;	
        line-height: 21px;
        opacity: 0.3;
        margin-top: 20px;
        border-bottom: 1px solid #ffffff;
        padding-bottom: 50px;
        margin-bottom: 10px;

        @include mq(1366) {
            font-size: 16px;		
            line-height: 19px;
        }

        @include mq(1200) {
            margin-right: 60px;
        }

        @include mq(992) {
            padding-bottom: 30px;
            font-size: 14px;
            margin-top: 5px;
        }

        @include mq(576) {
            margin-right: 0;
        }
    }

    &__description {
        padding-top: 20px;
        

        @include mq(1440) {
            padding-right: 0px;
        }

        @include mq(1200) {
            padding-right: 100px;
        }

        @include mq(992) {
            padding-top: 10px;
            padding-right: 0;
        }

        &_p {
            font-size: 18px;	
            font-weight: 400;	
            line-height: 36px;

            @include mq(1440) {
                font-size: 16px;
                line-height: 32px;
            }

            @include mq(1366) {
                font-size: 14px;
                line-height: 28px;
            }

            @include mq(1200) {
                padding-right: 60px;
            }

            @include mq(1024) {
                font-size: 14px;
                line-height: 28px;
            }

            @include mq(576) {
                padding-right: 0;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__socials {
        display: flex;
        margin-top: 40px;
    }

    &__social {
        svg {
            fill: rgba(#fff,1);
            margin: 0 6px;
            transition: all 0.3s ease-in-out;

            @include mq(1366) {
                width: 26px;
                height: 26px;
            }
        }

        &:hover {
            svg {
                fill: $main_blue;
            }
        }
    }

    .modal-content {
        background-image: var(--bg);
        background-repeat: no-repeat;
        background-position: center right;
        background-size: auto 100%;
    }
}