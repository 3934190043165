.menu-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: rgba(#000000,0.2);
    height: 100%;
    right: 0;
    top: 0;
    justify-content: center;
    border-left: 1px solid rgba(#ffffff,0.3);

    @include mq(992) {
        display: none;
    }
}

.menu-arrow {
    display: flex;
    background-color: $main_blue;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: all 0.2s ease-in-out;
    
    &__img {	
        width: 24px;
        height: 24px;
        transition: all 0.2s ease-in-out;

        &_gotop {
            transform: rotate(180deg);
        }

        @include mq(1024) {
            width: 20px;
            height: 20px;
        }
    }

    @include mq(1024) {
        height: 60px;
    }

    &_black {
        background-color: black;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 14px;
    font-family: Raleway;

    &__link {
        padding-left: 19px;
        padding-right: 30px;
        margin: 8px 0;
        color: #ffffff;
        opacity: 0.3;
        margin-left: -2.5px;
        user-select: none;
        box-sizing: border-box;
        border-left: 5px solid transparent;
        cursor: default;

        &_active {
            opacity: 1;
            border-left: 5px solid $main_blue;
        }

        &_whiteborder.menu__link_active {
            border-color: #ffffff;
        }

        &:hover {
            color: #ffffff;
            text-decoration: none;
            
        }

        &:not(.menu__link_active):hover {
            color: #ffffff;
            opacity: 0.5;
            text-decoration: none;
        }
    }
}

.menu-arrow-mobile {
    display: none;
    position: fixed;
    bottom: 25px;
    right: 25px;
    transition: all 0.2s ease-in-out;
    z-index: 10;

    svg {
        width: 14px;
        height: 14px;
    }

    @include mq(992) {
        display: flex;
    }

    &_gotop {
        transform: rotate(180deg);
    }
}