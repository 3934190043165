.service {
    display: flex;
    margin-bottom: 60px;
    align-items: center;

    @include mq(1440) {
        align-items: flex-start;
    }

    @include mq(1024) {
        margin-bottom: 30px;
    }

    @include mq(992) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__icon {
        &-img {
            width: 120px;

            @include mq(1440) {
                width: 100px;
            }

            @include mq(1024) {
                width: 80px;
            }
        }
        @include mq(992) {
            margin-bottom: 20px;
        }

    }

    &__info {
        display: flex;
        flex-direction: column;
        padding-left: 50px;

        @include mq(1024) {
            padding-left: 30px;
        }

        @include mq(992) {
            padding-left: 0;
        }
    }

    &__name {
        font-size: 28px;	
        font-weight: 800;	
        line-height: 26px;
        margin-bottom: 20px;

        @include mq(1440) {
            font-size: 24px;	
            line-height: 22px;
        }

        @include mq(1024) {
            font-size: 18px;	
            line-height: 20px;
        }

        @include mq(992) {
            text-align: center;
        }
    }

    &__description {
        opacity: 0.5;
        font-size: 14px;	
        font-weight: 500;	
        letter-spacing: 1px;	
        line-height: 25px;
        padding-right: 140px;

        @include mq(1440) {
            padding-right: 60px;
        }


        @include mq(1024) {
            padding-right: 0px;
            font-size: 12px;
            line-height: 23px;
        }

        @include mq(992) {
            text-align: center;
        }


    }


}