.serv-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 97%;
    height: 325px;
    border: 1px solid rgba(#ffffff,0.2);
    margin: 30px 0;
    padding: 35px 50px;
    transition:  all 0.3s ease-in-out;

    @include mq(1919) {
        height: 285px;
    }

    @include mq(1024) {
        height: 180px;
        padding: 25px 40px;
        margin: 10px 0;
        width: 100%;
    }

    @include mq(992) {
        padding: 20px 25px;
        height: 162px;
    }

    &__head {
        font-size: 27px;	
        font-weight: 800;	
        line-height: 32px;
        text-transform: capitalize;

        @include mq(1919) {
            font-size: 24px;
        }

        @include mq(1024) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__body {
        font-size: 16px;	
        font-weight: 500;	
        letter-spacing: 1.14px;	
        line-height: 28px;
        display: none;

        @include mq(1919) {
            font-size: 14px;
            line-height: 26px;
        }
    }

    &__footer {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        @include mq(1024) {
            justify-content: space-between;
        }
    }

    &__read-more {
        font-size: 16px;	
        font-weight: 500;	
        letter-spacing: 2px;	
        line-height: 19px;
        opacity: 0.7;
        user-select: none;
        display: none;

        &::after {
            content: '+';
            font-weight: 100;
            margin-left: 10px;
            font-size: 28px;
            line-height: 19px;
            vertical-align: middle;
            
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        @include mq(1024) {
            display: flex;
            font-size: 14px;
        }

        @include mq(992) {
            font-size: 12px;
        }
    }

    &__order-consult {
        font-size: 16px;	
        font-weight: 900;	
        letter-spacing: 2px;	
        line-height: 20px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        color: #ffffff;

        &::before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: $main_blue;
            margin-right: 13px;
        }

        &:hover {
            cursor: pointer;
            user-select: none;
            color: #000000;
            text-decoration: none;
        }

        @include mq(1024) {
            font-size: 14px;

            &_hidden {
                display: none;
            }
        }

        @include mq(992) {
            font-size: 14px;
        }
    }

    &:hover {
        background-color: $main_blue;
        box-shadow: 0 15px 50px 0 rgba(0,134,255,0.25);
        border-color: transparent;

        @include mq(1024) {
            background-color: transparent;
            box-shadow: none;
            border-color: rgba(#ffffff,0.2);
        }

        .serv-card {
            &__body {
                display: flex;

                @include mq(1024) {
                    display: none;
                }
            }

            &__footer {
                justify-content: space-between;
            }

            &__read-more {
                display: flex;
            }

            &__order-consult {
                &::before {
                    background-color: #000000;

                    @include mq(1024) {
                        background-color: $main_blue;
                    }
                }
            }
        }
    }
}