.innovations {
    padding-bottom: 110px;

    @include mq(1024) {
        padding-bottom: 70px;
    }

    &__bg-wrapper {
        padding-top: 648px;
        background: url('/img/bg_city.jpg') no-repeat 100%;
        background-size: cover;
        background-position: bottom;
        @include gp;

        @include mq(1024) {
            padding-top: 275px;
        }

        @include mq(992) {
            padding-top: 212px;

            background: linear-gradient(to bottom, transparent 0%, #000000 100%),
                url('/img/bg_city.jpg') no-repeat 100%;

            background-size:    100%, cover;
            background-position: center , bottom;
        }
    }
         
    &__photos-container {
        @include gp;
    }

    &__border {
        padding-right: 65px;
        border-right: 1px solid rgba(#ffffff,0.2);
        width: 100%;
        height: 100%;
    }

    &__head {
        font-size: 70px;	
        line-height: 70px;	
        text-align: right;

        @include mq(1440) {
            font-size: 50px;
            line-height: 50px;	
        }

        @include mq(1024) {
            font-size: 40px;	
            line-height: 40px;
        }

        @include mq(992) {
            text-align: left;
            font-size: 36px;	
            line-height: 36px;
        }
    }

    &__slogan {
        font-size: 22px;	
        font-weight: 500;	
        line-height: 30px;	
        text-align: right;
        margin-top: 50px;
        padding-left: 100px;

        @include mq(1440) {
            margin-top: 40px;
            font-size: 20px;
            line-height: 26px;
        }

        @include mq(1024) {
            font-size: 16px;
            line-height: 22px;
            padding-left: 40px;
        }

        @include mq(992) {
            font-size: 14px;
            line-height: 26px;
            text-align: left;
            padding-left: 0;
            margin-top: 20px;
        }
    }

    &__description {
        font-size: 18px;	
        font-weight: 500;	
        line-height: 36px;
        padding-left: 35px;

        @include mq(1440) {
            font-size: 16px;
            line-height: 30px;
        }

        @include mq(1024) {
            font-size: 13px;
            line-height: 26px;
        }

        @include mq(992) {
            padding-top: 25px;
            font-size: 12px;
            line-height: 24px;
            padding-left: 0;
        }
    }

    &__photo-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 40px;

        @include mq(1024) {
            padding-top: 10px;
        }
    }

    &__photo-row {
        display: flex;
        justify-content: space-between;
        margin-right: -40px;
        margin-bottom: 50px;

        @include mq(1024) {
            margin-bottom: 30px;
        }

        @include mq(992) {
            margin-right: 0;
        }
    }
}