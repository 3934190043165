.services {
    padding-top: 50px;

    @include gp;

    @include mq(992) {
        padding-top: 80px;
    } 

    &__head {
        position: relative;
        font-family: Raleway;	
        font-size: 28px;	
        font-weight: 500;	
        line-height: 40px;
        margin-bottom: 30px;
        text-transform: capitalize;

        @include mq(1919) {
            margin-bottom: 15px;
        }

        @include mq(992) {
            font-size: 24px;	
            line-height: 30px;
            margin-bottom: 15px;
        }
    }

    &__text {
        opacity: .5;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 25px;
        margin-bottom: 60px;

        @include mq(1919) {
            margin-bottom: 50px;
        }

        @include mq(992) {
            margin-bottom: 15px;
            padding-left: 30px;
            padding-right: 180px;
        }

        @include mq(576) {
            padding-right: 0;
            padding-left: 0;
        }

    }

    &__tab-name {
        font-size: 36px;	
        font-weight: 800;	
        line-height: 32px;
        margin-bottom: 100px;
        margin-left: 40px;
        text-transform: capitalize;

        @include mq(1024) {
            font-size: 30px;	
            line-height: 32px;
            margin-bottom: 60px;
        }

        @include mq(992) {
            margin-left: 0;
        } 
    }

    &__col {
        border-left: 1px solid rgba(#fff, 0.2);
        padding-left: 60px;
        margin: 0 10px;

        @include mq(992) {
            margin: 0;
            padding-left: 15px;
            border-left: none;
            border-top: 1px solid rgba(#fff, 0.2);
            margin-top: 20px;
            padding-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}