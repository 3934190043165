.interested {
    background-color: $main_blue;
    width: 83%;
    margin: 0 auto;
    transform: translateY(100px);
    padding: 50px 70px 60px 70px;
    box-shadow: 0 20px 90px rgba(#000,0.35);

    @include mq(576) {
        width: 100%;
        transform: translateY(50px);
        padding: 40px 25px;
    }

    &__head {
        font-size: 44px;	
        font-weight: 500;	
        line-height: 70px;
        margin-bottom: 0px;
    }

    &__text {
        font-size: 18px;	
        font-weight: 400;	
        line-height: 36px;
        margin-bottom: 40px;
    }

    &__form {
        margin-top: 0;
    }

    .form {
        &__input {
            background-color: transparent;
            border: 1px solid #ffffff;
            margin-right: 15px;
        }

        &__submit {
            color: #ffffff;
            display: flex;
            flex-grow: 1;
            flex-basis: 80%;
            font-size: 16px;	
            font-weight: 900;	
            letter-spacing: 2px;	
            line-height: 19px;
            text-transform: uppercase;
            align-items: center;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%,50%);

            svg {
                height: 16px;
                margin-left: 15px;
            }

            &:hover {
                color: #000000;
            }

            @include mq(576) {
                width: 80%;
            }
        }

        &__checkbox {
            margin: 0;
            margin-top: 30px;
        }
    }
}