.modal {
    padding-right: 0 !important;
    background-color: #000000 !important;

    &-dialog {
        max-width: 100%;
        margin: 0;
        
    }

    &-body {
        
        .container {
            height: 100%;
        }
    }

    &-content {
        border: none;
        border-radius: 0;
        min-height: 100vh;
        overflow: hidden;
        background-color: transparent;
        color: #ffffff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
    }

    &-header {
        border-bottom: none;
        margin-bottom: 50px;

        @include mq(992) {
            margin-bottom: 20px;
        }
    }

    &-bg {
        flex-direction: column;
        flex-shrink: 1;
        background-image: url('/img/bg_dot.png');
        height: 100%;
        background-position: -13px 136px;
        background-repeat: no-repeat;
        background-size: 70%;

        @include mq(992) {
            background-image: none;
        }
    }

    .back {
        display: flex;
        align-items: flex-end;
        border: none;
        background-color: transparent;
        opacity: 0.5;	
        color: #FFFFFF;	
        font-family: Raleway;	
        font-size: 12px;	
        font-weight: 400;	
        text-transform: uppercase;
        line-height: 18px;
        opacity: 1;
        

        &__img {
            width: 26px;
            height: 26px;
            margin-right: 10px;
        }

        &__text {
            opacity: 0.4;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            cursor: pointer;
    
            .back__text {
                opacity: 1;
            }
        }

        &:focus, &:active {
            outline: 0px;
        }
    }

    &__undercover {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        &-img {
            max-height: 100vh;
            height: auto;
        }

        &-filter {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(#000,1) 0%, rgba(#000,0.1) 30%,transparent 100%);
            z-index: 2;
        }

        @include mq(992) {
            transform: translateX(30%);
        }

        @include mq(767) {
            display: none;
        }
    }
}