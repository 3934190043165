.button {
    display: flex;
    background-color: transparent;
    border: 1px solid rgba(#ffffff,0.2);
    position: relative;
    height: 88px;	
    width: 330px;
    overflow: hidden;
    user-select: none;
    
    
    @include mq(1919) {
        height: 70px;
        width: 280px;
    }

    @include mq(1366) {
        width: 260px;
    }

    @include mq(992) {
        width: 100%;
        background-color: $main-blue;
        border: 1px solid $main-blue;
    }
    

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px 60px;
        z-index: 2;

        @include mq(1919) {
            padding: 35px 30px;
        }

        @include mq(1366) {
            padding: 35px 0px;
        }
    }

    &__text {
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 16px;
        font-weight: 900;
        font-family: Raleway;
    }

    &__icon {
        content: '';
        display: inline-block;
        background-color: $main_blue;
        height: 8px;
        width: 8px;
        margin-right: 13px;     

        @include mq(992) {
            background-color: #000000;
        }
    }

    &::before {
        content: '';
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        background-color: $main_blue;
        transition: all 0.2s ease-in-out;
    }

    @media only screen and (min-width: 993px) {
        &:hover {
            cursor: pointer;
            border-color: $main_blue;
    
            &::before {
                transform: translateX(0);
            }
    
            .button__icon {
                background-color: #000000;
            }
        }
    }

    &:focus, &:active {
        outline: 0px;
    }
}
