@import '../../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../../node_modules/bootstrap/scss/_transitions.scss';
@import '../../node_modules/bootstrap/scss/_modal.scss';



@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,700');

@import '_variables';
@import '_mixins';
@import '_helpers';


@import 'components/menu';
@import 'components/logo';
@import 'components/header';
@import 'components/button';
@import 'components/services';
@import 'components/serv-card';
@import 'components/innovations';
@import 'components/photo';
@import 'components/footer';
@import 'components/form';
@import 'components/anchors';
@import 'components/modal';
@import 'components/person-modal';
@import 'components/service';
@import 'components/interested';

.wrapper {
    background: #000000 url('/img/bg_dot.png') no-repeat;
    background-position: 83% -155px;
    color: #ffffff;
    font-family: Raleway;

    @include mq(1919) {
        background-position: 83% -295px;
    }

    @include mq(1366) {
        background-position: 83% -495px;
    }

    @include mq(1024) {
        background-position: 83% -295px;
        background-size: 34%;
    }

    @include mq(992) {
        background-position: 83% -895px;
        background-size: 51%;
    }
}

