.photo {
    margin: 0 40px;

    @include mq(1024) {
        &:nth-of-type(1n) {
            margin-right: 10px;
        }

        &:nth-of-type(2n) {
            margin-left: 10px;
        }
    }

    @include mq(992) {
        margin: 0 0px;
    }

    &__container {
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            .photo__cover {
                opacity: 1;
                
                transform: translateX(0);
                color: #fff;
            }

            .photo__img {
                filter: grayscale(100%);
            }
        }
    }

    &__img {
        width:100%;
        transition: all 0.3s ease-in-out;

        @include mq(992) {
            max-width: 100%;
        }
    }

    &__cover {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: transparent;
        background-color: rgba($main_blue,0.8);
        font-size: 14px;	
        font-weight: 900;	
        letter-spacing: 1.75px;	
        line-height: 16px;
        text-transform: uppercase;
        user-select: none;
        transition: transform 0.4s cubic-bezier(0, 0.35, 0.41, 0.98),
                    color 0.3s ease-in-out 0.3s;
        opacity: 0;
        transform: translateX(-100%);
    }

    &__name {
        font-size: 16px;	
        font-weight: bold;	
        line-height: 19px;	
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 20px;

        @include mq(1024) {
            font-size: 13px;	
            line-height: 16px;
        }
    }

    &__post {
        opacity: 0.3;
        font-size: 14px;	
        font-weight: 600;	
        line-height: 16px;	
        text-align: center;
        margin-bottom: 5px;

        @include mq(1024) {
            font-size: 12px;	
            line-height: 14px;
        }
    }

    &__socials {
        display: flex;
        justify-content: center;
    }

    &__social {
        svg {
            fill: rgba(#fff,0.5);
            margin: 0 6px;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            svg {
                fill: $main_blue;
            }
        }
    }
}