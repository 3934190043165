@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,700");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.text-blue {
  color: #006DFF; }

.container {
  max-width: 1440px; }
  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1260px; } }
  @media only screen and (max-width: 1440px) {
    .container {
      max-width: 1100px; } }
  @media only screen and (max-width: 1024px) {
    .container {
      max-width: 800px; } }

.fat {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1; }
  @media only screen and (max-width: 992px) {
    .fat_nomobile {
      display: none; } }
  @media only screen and (max-width: 576px) {
    .fat_nomobile_xs {
      display: none; } }

@media only screen and (max-width: 992px) {
  .no-mobile {
    display: none; } }

@media only screen and (min-width: 993px) {
  .no-desktop {
    display: none; } }

.menu-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  right: 0;
  top: 0;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.3); }
  @media only screen and (max-width: 992px) {
    .menu-wrapper {
      display: none; } }

.menu-arrow {
  display: flex;
  background-color: #006DFF;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: all 0.2s ease-in-out; }
  .menu-arrow__img {
    width: 24px;
    height: 24px;
    transition: all 0.2s ease-in-out; }
    .menu-arrow__img_gotop {
      transform: rotate(180deg); }
    @media only screen and (max-width: 1024px) {
      .menu-arrow__img {
        width: 20px;
        height: 20px; } }
  @media only screen and (max-width: 1024px) {
    .menu-arrow {
      height: 60px; } }
  .menu-arrow_black {
    background-color: black; }

.menu {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-size: 14px;
  font-family: Raleway; }
  .menu__link {
    padding-left: 19px;
    padding-right: 30px;
    margin: 8px 0;
    color: #ffffff;
    opacity: 0.3;
    margin-left: -2.5px;
    user-select: none;
    box-sizing: border-box;
    border-left: 5px solid transparent;
    cursor: default; }
    .menu__link_active {
      opacity: 1;
      border-left: 5px solid #006DFF; }
    .menu__link_whiteborder.menu__link_active {
      border-color: #ffffff; }
    .menu__link:hover {
      color: #ffffff;
      text-decoration: none; }
    .menu__link:not(.menu__link_active):hover {
      color: #ffffff;
      opacity: 0.5;
      text-decoration: none; }

.menu-arrow-mobile {
  display: none;
  position: fixed;
  bottom: 25px;
  right: 25px;
  transition: all 0.2s ease-in-out;
  z-index: 10; }
  .menu-arrow-mobile svg {
    width: 14px;
    height: 14px; }
  @media only screen and (max-width: 992px) {
    .menu-arrow-mobile {
      display: flex; } }
  .menu-arrow-mobile_gotop {
    transform: rotate(180deg); }

.logo {
  padding: 40px 40px; }
  .logo__img {
    width: 81px;
    height: auto; }
  @media only screen and (max-width: 992px) {
    .logo {
      padding: 40px 20px; } }

.header {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 104px;
  padding-right: 110px; }
  @media only screen and (max-width: 992px) {
    .header {
      padding-right: 0; } }
  @media only screen and (max-width: 992px) {
    .header {
      max-height: 92vh;
      padding-bottom: 60px; } }
  @media only screen and (orientation: landscape) and (max-width: 992px) {
    .header {
      min-height: 500px; } }
  .header__h1 {
    font-size: 88px;
    line-height: 92px;
    margin: 0;
    padding: 0;
    font-weight: normal;
    margin-bottom: 50px; }
    @media only screen and (max-width: 1919px) {
      .header__h1 {
        font-size: 64px;
        line-height: 68px; } }
    @media only screen and (max-width: 1024px) {
      .header__h1 {
        font-size: 56px;
        line-height: 60px; } }
    @media only screen and (max-width: 992px) {
      .header__h1 {
        font-size: 60px;
        line-height: 60px; } }
    @media only screen and (max-width: 576px) {
      .header__h1 {
        font-size: 35.2px;
        line-height: 35.2px; } }
  .header__desc {
    opacity: 0.8;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 38px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 1919px) {
      .header__desc {
        font-size: 16px;
        line-height: 24px; } }
    @media only screen and (max-width: 992px) {
      .header__desc {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 80px; } }
    @media only screen and (max-width: 576px) {
      .header__desc {
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 80px; } }

.button {
  display: flex;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  height: 88px;
  width: 330px;
  overflow: hidden;
  user-select: none; }
  @media only screen and (max-width: 1919px) {
    .button {
      height: 70px;
      width: 280px; } }
  @media only screen and (max-width: 1366px) {
    .button {
      width: 260px; } }
  @media only screen and (max-width: 992px) {
    .button {
      width: 100%;
      background-color: #006DFF;
      border: 1px solid #006DFF; } }
  .button__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 60px;
    z-index: 2; }
    @media only screen and (max-width: 1919px) {
      .button__inner {
        padding: 35px 30px; } }
    @media only screen and (max-width: 1366px) {
      .button__inner {
        padding: 35px 0px; } }
  .button__text {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 900;
    font-family: Raleway; }
  .button__icon {
    content: '';
    display: inline-block;
    background-color: #006DFF;
    height: 8px;
    width: 8px;
    margin-right: 13px; }
    @media only screen and (max-width: 992px) {
      .button__icon {
        background-color: #000000; } }
  .button::before {
    content: '';
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    background-color: #006DFF;
    transition: all 0.2s ease-in-out; }
  @media only screen and (min-width: 993px) {
    .button:hover {
      cursor: pointer;
      border-color: #006DFF; }
      .button:hover::before {
        transform: translateX(0); }
      .button:hover .button__icon {
        background-color: #000000; } }
  .button:focus, .button:active {
    outline: 0px; }

.services {
  padding-top: 50px;
  padding-right: 110px; }
  @media only screen and (max-width: 992px) {
    .services {
      padding-right: 0; } }
  @media only screen and (max-width: 992px) {
    .services {
      padding-top: 80px; } }
  .services__head {
    position: relative;
    font-family: Raleway;
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 30px;
    text-transform: capitalize; }
    @media only screen and (max-width: 1919px) {
      .services__head {
        margin-bottom: 15px; } }
    @media only screen and (max-width: 992px) {
      .services__head {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 15px; } }
  .services__text {
    opacity: .5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 25px;
    margin-bottom: 60px; }
    @media only screen and (max-width: 1919px) {
      .services__text {
        margin-bottom: 50px; } }
    @media only screen and (max-width: 992px) {
      .services__text {
        margin-bottom: 15px;
        padding-left: 30px;
        padding-right: 180px; } }
    @media only screen and (max-width: 576px) {
      .services__text {
        padding-right: 0;
        padding-left: 0; } }
  .services__tab-name {
    font-size: 36px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 100px;
    margin-left: 40px;
    text-transform: capitalize; }
    @media only screen and (max-width: 1024px) {
      .services__tab-name {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 60px; } }
    @media only screen and (max-width: 992px) {
      .services__tab-name {
        margin-left: 0; } }
  .services__col {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 60px;
    margin: 0 10px; }
    @media only screen and (max-width: 992px) {
      .services__col {
        margin: 0;
        padding-left: 15px;
        border-left: none;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        margin-top: 20px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center; } }

.serv-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 97%;
  height: 325px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 30px 0;
  padding: 35px 50px;
  transition: all 0.3s ease-in-out; }
  @media only screen and (max-width: 1919px) {
    .serv-card {
      height: 285px; } }
  @media only screen and (max-width: 1024px) {
    .serv-card {
      height: 180px;
      padding: 25px 40px;
      margin: 10px 0;
      width: 100%; } }
  @media only screen and (max-width: 992px) {
    .serv-card {
      padding: 20px 25px;
      height: 162px; } }
  .serv-card__head {
    font-size: 27px;
    font-weight: 800;
    line-height: 32px;
    text-transform: capitalize; }
    @media only screen and (max-width: 1919px) {
      .serv-card__head {
        font-size: 24px; } }
    @media only screen and (max-width: 1024px) {
      .serv-card__head {
        font-size: 20px;
        line-height: 24px; } }
  .serv-card__body {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.14px;
    line-height: 28px;
    display: none; }
    @media only screen and (max-width: 1919px) {
      .serv-card__body {
        font-size: 14px;
        line-height: 26px; } }
  .serv-card__footer {
    display: flex;
    width: 100%;
    justify-content: flex-end; }
    @media only screen and (max-width: 1024px) {
      .serv-card__footer {
        justify-content: space-between; } }
  .serv-card__read-more {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 19px;
    opacity: 0.7;
    user-select: none;
    display: none; }
    .serv-card__read-more::after {
      content: '+';
      font-weight: 100;
      margin-left: 10px;
      font-size: 28px;
      line-height: 19px;
      vertical-align: middle; }
    .serv-card__read-more:hover {
      cursor: pointer;
      opacity: 1; }
    @media only screen and (max-width: 1024px) {
      .serv-card__read-more {
        display: flex;
        font-size: 14px; } }
    @media only screen and (max-width: 992px) {
      .serv-card__read-more {
        font-size: 12px; } }
  .serv-card__order-consult {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #ffffff; }
    .serv-card__order-consult::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: #006DFF;
      margin-right: 13px; }
    .serv-card__order-consult:hover {
      cursor: pointer;
      user-select: none;
      color: #000000;
      text-decoration: none; }
    @media only screen and (max-width: 1024px) {
      .serv-card__order-consult {
        font-size: 14px; }
        .serv-card__order-consult_hidden {
          display: none; } }
    @media only screen and (max-width: 992px) {
      .serv-card__order-consult {
        font-size: 14px; } }
  .serv-card:hover {
    background-color: #006DFF;
    box-shadow: 0 15px 50px 0 rgba(0, 134, 255, 0.25);
    border-color: transparent; }
    @media only screen and (max-width: 1024px) {
      .serv-card:hover {
        background-color: transparent;
        box-shadow: none;
        border-color: rgba(255, 255, 255, 0.2); } }
    .serv-card:hover .serv-card__body {
      display: flex; }
      @media only screen and (max-width: 1024px) {
        .serv-card:hover .serv-card__body {
          display: none; } }
    .serv-card:hover .serv-card__footer {
      justify-content: space-between; }
    .serv-card:hover .serv-card__read-more {
      display: flex; }
    .serv-card:hover .serv-card__order-consult::before {
      background-color: #000000; }
      @media only screen and (max-width: 1024px) {
        .serv-card:hover .serv-card__order-consult::before {
          background-color: #006DFF; } }

.innovations {
  padding-bottom: 110px; }
  @media only screen and (max-width: 1024px) {
    .innovations {
      padding-bottom: 70px; } }
  .innovations__bg-wrapper {
    padding-top: 648px;
    background: url("/img/bg_city.jpg") no-repeat 100%;
    background-size: cover;
    background-position: bottom;
    padding-right: 110px; }
    @media only screen and (max-width: 992px) {
      .innovations__bg-wrapper {
        padding-right: 0; } }
    @media only screen and (max-width: 1024px) {
      .innovations__bg-wrapper {
        padding-top: 275px; } }
    @media only screen and (max-width: 992px) {
      .innovations__bg-wrapper {
        padding-top: 212px;
        background: linear-gradient(to bottom, transparent 0%, #000000 100%), url("/img/bg_city.jpg") no-repeat 100%;
        background-size: 100%, cover;
        background-position: center , bottom; } }
  .innovations__photos-container {
    padding-right: 110px; }
    @media only screen and (max-width: 992px) {
      .innovations__photos-container {
        padding-right: 0; } }
  .innovations__border {
    padding-right: 65px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    height: 100%; }
  .innovations__head {
    font-size: 70px;
    line-height: 70px;
    text-align: right; }
    @media only screen and (max-width: 1440px) {
      .innovations__head {
        font-size: 50px;
        line-height: 50px; } }
    @media only screen and (max-width: 1024px) {
      .innovations__head {
        font-size: 40px;
        line-height: 40px; } }
    @media only screen and (max-width: 992px) {
      .innovations__head {
        text-align: left;
        font-size: 36px;
        line-height: 36px; } }
  .innovations__slogan {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    text-align: right;
    margin-top: 50px;
    padding-left: 100px; }
    @media only screen and (max-width: 1440px) {
      .innovations__slogan {
        margin-top: 40px;
        font-size: 20px;
        line-height: 26px; } }
    @media only screen and (max-width: 1024px) {
      .innovations__slogan {
        font-size: 16px;
        line-height: 22px;
        padding-left: 40px; } }
    @media only screen and (max-width: 992px) {
      .innovations__slogan {
        font-size: 14px;
        line-height: 26px;
        text-align: left;
        padding-left: 0;
        margin-top: 20px; } }
  .innovations__description {
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    padding-left: 35px; }
    @media only screen and (max-width: 1440px) {
      .innovations__description {
        font-size: 16px;
        line-height: 30px; } }
    @media only screen and (max-width: 1024px) {
      .innovations__description {
        font-size: 13px;
        line-height: 26px; } }
    @media only screen and (max-width: 992px) {
      .innovations__description {
        padding-top: 25px;
        font-size: 12px;
        line-height: 24px;
        padding-left: 0; } }
  .innovations__photo-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 40px; }
    @media only screen and (max-width: 1024px) {
      .innovations__photo-wrapper {
        padding-top: 10px; } }
  .innovations__photo-row {
    display: flex;
    justify-content: space-between;
    margin-right: -40px;
    margin-bottom: 50px; }
    @media only screen and (max-width: 1024px) {
      .innovations__photo-row {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 992px) {
      .innovations__photo-row {
        margin-right: 0; } }

.photo {
  margin: 0 40px; }
  @media only screen and (max-width: 1024px) {
    .photo:nth-of-type(1n) {
      margin-right: 10px; }
    .photo:nth-of-type(2n) {
      margin-left: 10px; } }
  @media only screen and (max-width: 992px) {
    .photo {
      margin: 0 0px; } }
  .photo__container {
    position: relative;
    overflow: hidden;
    cursor: pointer; }
    .photo__container:hover .photo__cover {
      opacity: 1;
      transform: translateX(0);
      color: #fff; }
    .photo__container:hover .photo__img {
      filter: grayscale(100%); }
  .photo__img {
    width: 100%;
    transition: all 0.3s ease-in-out; }
    @media only screen and (max-width: 992px) {
      .photo__img {
        max-width: 100%; } }
  .photo__cover {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    background-color: rgba(0, 109, 255, 0.8);
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1.75px;
    line-height: 16px;
    text-transform: uppercase;
    user-select: none;
    transition: transform 0.4s cubic-bezier(0, 0.35, 0.41, 0.98), color 0.3s ease-in-out 0.3s;
    opacity: 0;
    transform: translateX(-100%); }
  .photo__name {
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 20px; }
    @media only screen and (max-width: 1024px) {
      .photo__name {
        font-size: 13px;
        line-height: 16px; } }
  .photo__post {
    opacity: 0.3;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin-bottom: 5px; }
    @media only screen and (max-width: 1024px) {
      .photo__post {
        font-size: 12px;
        line-height: 14px; } }
  .photo__socials {
    display: flex;
    justify-content: center; }
  .photo__social svg {
    fill: rgba(255, 255, 255, 0.5);
    margin: 0 6px;
    transition: all 0.3s ease-in-out; }
  .photo__social:hover svg {
    fill: #006DFF; }

.footer {
  background-color: #006DFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  padding-right: 110px; }
  @media only screen and (max-width: 992px) {
    .footer {
      padding-right: 0; } }
  @media only screen and (max-width: 1440px) {
    .footer {
      padding-top: 0px; } }
  @media only screen and (max-width: 992px) {
    .footer {
      padding-top: 45px;
      padding-left: 40px; } }
  @media only screen and (max-width: 576px) {
    .footer {
      padding-left: 0;
      padding-top: 20px; } }
  .footer__head {
    font-size: 80px;
    font-weight: 500;
    line-height: 115px; }
    @media only screen and (max-width: 1440px) {
      .footer__head {
        font-size: 70px;
        line-height: 115px; } }
    @media only screen and (max-width: 1024px) {
      .footer__head {
        font-size: 60px;
        line-height: 115px; } }
    @media only screen and (max-width: 992px) {
      .footer__head {
        font-size: 40px;
        line-height: 44px; } }
  .footer__subhead {
    opacity: 0.7;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px; }
    @media only screen and (max-width: 992px) {
      .footer__subhead {
        font-size: 12px;
        line-height: 24px;
        margin-top: 25px; } }
    @media only screen and (max-width: 576px) {
      .footer__subhead {
        margin-top: 15px; } }
  .footer__or-write {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    margin-top: 50px;
    opacity: 0.7;
    margin-bottom: 30px; }
    @media only screen and (max-width: 1366px) {
      .footer__or-write {
        margin-bottom: 10px;
        margin-top: 30px; } }
    @media only screen and (max-width: 992px) {
      .footer__or-write {
        font-size: 12px;
        line-height: 24px;
        margin-top: 40px;
        margin-bottom: 10px; } }
    @media only screen and (max-width: 576px) {
      .footer__or-write {
        margin-top: 20px; } }
  .footer__socials {
    display: flex; }
    @media only screen and (max-width: 576px) {
      .footer__socials {
        flex-direction: column; } }
  .footer__social {
    display: flex;
    align-items: center; }
    .footer__social-img {
      width: 26px;
      height: 22px;
      margin-right: 17px; }
      @media only screen and (max-width: 1440px) {
        .footer__social-img {
          width: 24px;
          height: 20px; } }
      @media only screen and (max-width: 576px) {
        .footer__social-img {
          width: 18px;
          height: 14px;
          margin-right: 8px; } }
    .footer__social-text {
      font-size: 23px;
      font-weight: 700;
      letter-spacing: 2.88px;
      line-height: 27px;
      color: #ffffff; }
      @media only screen and (max-width: 1440px) {
        .footer__social-text {
          font-size: 20px; } }
      @media only screen and (max-width: 576px) {
        .footer__social-text {
          font-size: 14px; } }
    .footer__social:hover {
      text-decoration: none; }
    .footer__social_email {
      margin-right: 70px; }
      @media only screen and (max-width: 576px) {
        .footer__social_email {
          margin-right: 0;
          margin-bottom: 10px; } }
      @media only screen and (max-width: 576px) {
        .footer__social_email {
          margin-bottom: 0px; } }
  .footer__bottom {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    padding-bottom: 10px; }
    @media only screen and (max-width: 992px) {
      .footer__bottom {
        flex-wrap: wrap;
        padding-right: 0;
        padding: 0 10px; } }
  .footer__copyright, .footer__terms, .footer__else {
    padding: 0 37px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    opacity: 0.7; }
    @media only screen and (max-width: 1366px) {
      .footer__copyright, .footer__terms, .footer__else {
        font-size: 12px; } }
    @media only screen and (max-width: 1024px) {
      .footer__copyright, .footer__terms, .footer__else {
        padding: 0 15px; } }
    @media only screen and (max-width: 992px) {
      .footer__copyright, .footer__terms, .footer__else {
        font-size: 10px;
        padding: 0; } }
  .footer__copyright_mobile {
    flex-basis: 100%;
    padding-left: 10px;
    margin-left: 30px; }
    .footer__copyright_mobile a {
      color: white; }
    @media only screen and (max-width: 768px) {
      .footer__copyright_mobile {
        margin-left: 0;
        padding-left: 0; } }
  .footer__copyright a {
    transition: all .3s ease-in-out; }
    .footer__copyright a:hover {
      text-decoration: none; }
  .footer__copyright:hover {
    opacity: 1;
    color: rgba(255, 255, 255, 0.7); }
    .footer__copyright:hover a {
      color: #fff; }
  .footer__terms {
    color: #ffffff; }
    .footer__terms:hover {
      text-decoration: none;
      color: #ffffff;
      opacity: 1; }
    @media only screen and (max-width: 992px) {
      .footer__terms {
        margin-left: 40px;
        margin-right: 40px; } }
    @media only screen and (max-width: 768px) {
      .footer__terms {
        margin-left: 0; } }
    @media only screen and (max-width: 576px) {
      .footer__terms {
        margin-left: 10px;
        margin-right: 10px; } }
  .footer__links {
    display: flex; }
    .footer__links a {
      color: #ffffff; }
    @media only screen and (max-width: 992px) {
      .footer__links {
        flex-basis: 50%; } }
    @media only screen and (max-width: 576px) {
      .footer__links {
        margin-bottom: 10px;
        flex-basis: 70%; } }
  .footer__bottom-socials {
    display: flex; }
    @media only screen and (max-width: 992px) {
      .footer__bottom-socials {
        flex-basis: 50%; } }
    @media only screen and (max-width: 576px) {
      .footer__bottom-socials {
        padding-right: 40px;
        flex-basis: 30%; } }
  .footer__bottom-social {
    display: flex;
    margin-right: 20px;
    color: rgba(255, 255, 255, 0.5);
    align-items: center;
    transition: all .3s ease-in-out; }
    @media only screen and (max-width: 576px) {
      .footer__bottom-social {
        margin-right: 0; } }
    .footer__bottom-social svg {
      width: 25px;
      height: 25px;
      fill: rgba(255, 255, 255, 0.5);
      margin-right: 10px;
      transition: all .3s ease-in-out; }
      @media only screen and (max-width: 576px) {
        .footer__bottom-social svg {
          width: 20px;
          height: 20px; } }
    .footer__bottom-social:hover {
      color: white;
      text-decoration: none; }
      .footer__bottom-social:hover svg {
        fill: #fff; }
    @media only screen and (max-width: 576px) {
      .footer__bottom-social-text {
        display: none; } }

.form {
  margin-top: 95px; }
  @media only screen and (max-width: 1600px) {
    .form {
      margin-top: 75px; } }
  @media only screen and (max-width: 1440px) {
    .form {
      margin-top: 55px; } }
  @media only screen and (max-width: 1366px) {
    .form {
      margin-top: 30px; } }
  @media only screen and (max-width: 992px) {
    .form {
      width: 65%; } }
  @media only screen and (max-width: 576px) {
    .form {
      width: 100%; } }
  .form__row {
    display: flex; }
    @media only screen and (max-width: 992px) {
      .form__row {
        flex-direction: column; } }
    .form__row_center {
      justify-content: center; }
  .form__input {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 2.25px;
    line-height: 20px;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    border: none;
    background-color: transparent;
    color: #ffffff;
    padding: 34px 38px;
    border-radius: 0px;
    border: 1px solid #ffffff;
    margin: 0 10px; }
    .form__input::placeholder {
      opacity: 0.7;
      color: #ffffff; }
    @media only screen and (max-width: 1440px) {
      .form__input {
        padding: 25px 38px; } }
    @media only screen and (max-width: 1366px) {
      .form__input {
        padding: 21px 25px;
        font-size: 16px; } }
    @media only screen and (max-width: 992px) {
      .form__input {
        width: 100%;
        margin: 0;
        margin-bottom: 10px; } }
    @media only screen and (max-width: 576px) {
      .form__input {
        padding: 17px 25px; } }
    .form__input:focus, .form__input:active {
      outline: 0px; }
  .form__submit {
    width: 88px;
    background-color: #000000;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 90px;
    margin: 0 10px;
    width: 346px;
    color: #ffffff; }
    .form__submit_text {
      color: inherit;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 19px;
      text-transform: uppercase;
      font-family: Raleway; }
    .form__submit svg {
      width: 24px;
      height: 24px;
      transform: rotate(-90deg);
      fill: #ffffff;
      margin-left: 15px;
      height: 18px; }
      .form__submit svg.success {
        transform: rotate(0deg); }
      .form__submit svg.hidden {
        display: none; }
      @media only screen and (max-width: 1440px) {
        .form__submit svg {
          width: 20px;
          height: 20px; } }
      @media only screen and (max-width: 576px) {
        .form__submit svg {
          width: 16px;
          height: 16px; } }
    .form__submit_inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      background-color: transparent; }
    .form__submit::before {
      content: '';
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-110%);
      background-color: #ffffff;
      transition: all 0.2s ease-in-out; }
    @media only screen and (max-width: 1440px) {
      .form__submit {
        height: 72px; } }
    @media only screen and (max-width: 1366px) {
      .form__submit {
        height: 64px;
        width: 300px; } }
    @media only screen and (min-width: 993px) {
      .form__submit:hover {
        cursor: pointer;
        color: #000000; }
        .form__submit:hover::before {
          transform: translateX(0); }
        .form__submit:hover svg {
          fill: #006DFF; } }
    @media only screen and (max-width: 992px) {
      .form__submit {
        width: 300px;
        margin: 0; } }
    @media only screen and (max-width: 576px) {
      .form__submit {
        height: 56px;
        width: 100%; } }
    .form__submit:focus, .form__submit:active {
      outline: 0px; }
  .form__checkbox {
    position: relative;
    margin: 50px 10px;
    display: flex;
    align-items: center; }
    .form__checkbox-core {
      opacity: 0;
      width: 1px;
      height: 1px;
      position: absolute;
      z-index: -1; }
      .form__checkbox-core:checked + label::before {
        content: '✓';
        color: #ffffff; }
    .form__checkbox-label {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      padding-left: 40px;
      margin-bottom: 0; }
      @media only screen and (max-width: 576px) {
        .form__checkbox-label {
          font-size: 12px;
          line-height: 24px;
          padding-left: 30px; } }
      .form__checkbox-label::before {
        content: '';
        display: flex;
        justify-content: center;
        align-items: center;
        width: 19px;
        height: 19px;
        border: 3px solid #ffffff;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%); }
        @media only screen and (max-width: 576px) {
          .form__checkbox-label::before {
            width: 16px;
            height: 16px;
            border-width: 2px; } }
      .form__checkbox-label:hover {
        cursor: pointer; }
    @media only screen and (max-width: 1366px) {
      .form__checkbox {
        margin: 30px 10px; } }
    @media only screen and (max-width: 576px) {
      .form__checkbox {
        margin: 10px 10px 15px 10px; } }
  .form__link {
    color: #fff;
    text-decoration: underline; }
    .form__link:hover {
      color: #000000; }

#services_anchor {
  position: relative;
  top: -50px; }

#about_anchor {
  position: relative;
  top: 230px; }

.modal {
  padding-right: 0 !important;
  background-color: #000000 !important; }
  .modal-dialog {
    max-width: 100%;
    margin: 0; }
  .modal-body .container {
    height: 100%; }
  .modal-content {
    border: none;
    border-radius: 0;
    min-height: 100vh;
    overflow: hidden;
    background-color: transparent;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%; }
  .modal-header {
    border-bottom: none;
    margin-bottom: 50px; }
    @media only screen and (max-width: 992px) {
      .modal-header {
        margin-bottom: 20px; } }
  .modal-bg {
    flex-direction: column;
    flex-shrink: 1;
    background-image: url("/img/bg_dot.png");
    height: 100%;
    background-position: -13px 136px;
    background-repeat: no-repeat;
    background-size: 70%; }
    @media only screen and (max-width: 992px) {
      .modal-bg {
        background-image: none; } }
  .modal .back {
    display: flex;
    align-items: flex-end;
    border: none;
    background-color: transparent;
    opacity: 0.5;
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 18px;
    opacity: 1; }
    .modal .back__img {
      width: 26px;
      height: 26px;
      margin-right: 10px; }
    .modal .back__text {
      opacity: 0.4;
      transition: all 0.2s ease-in-out; }
    .modal .back:hover {
      cursor: pointer; }
      .modal .back:hover .back__text {
        opacity: 1; }
    .modal .back:focus, .modal .back:active {
      outline: 0px; }
  .modal__undercover {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1; }
    .modal__undercover-img {
      max-height: 100vh;
      height: auto; }
    .modal__undercover-filter {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0.1) 30%, transparent 100%);
      z-index: 2; }
    @media only screen and (max-width: 992px) {
      .modal__undercover {
        transform: translateX(30%); } }
    @media only screen and (max-width: 767px) {
      .modal__undercover {
        display: none; } }

.person-modal {
  font-family: Raleway, sans-serif; }
  .person-modal__title {
    font-size: 46px;
    font-weight: 700;
    line-height: 60px;
    text-transform: uppercase; }
    @media only screen and (max-width: 1440px) {
      .person-modal__title {
        font-size: 38px;
        line-height: 50px; } }
    @media only screen and (max-width: 1366px) {
      .person-modal__title {
        font-size: 32px;
        line-height: 46px; } }
    @media only screen and (max-width: 1024px) {
      .person-modal__title {
        font-size: 32px;
        line-height: 46px; } }
    @media only screen and (max-width: 992px) {
      .person-modal__title {
        font-size: 26px;
        line-height: 34px; } }
  .person-modal__post {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    opacity: 0.3;
    margin-top: 20px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 50px;
    margin-bottom: 10px; }
    @media only screen and (max-width: 1366px) {
      .person-modal__post {
        font-size: 16px;
        line-height: 19px; } }
    @media only screen and (max-width: 1200px) {
      .person-modal__post {
        margin-right: 60px; } }
    @media only screen and (max-width: 992px) {
      .person-modal__post {
        padding-bottom: 30px;
        font-size: 14px;
        margin-top: 5px; } }
    @media only screen and (max-width: 576px) {
      .person-modal__post {
        margin-right: 0; } }
  .person-modal__description {
    padding-top: 20px; }
    @media only screen and (max-width: 1440px) {
      .person-modal__description {
        padding-right: 0px; } }
    @media only screen and (max-width: 1200px) {
      .person-modal__description {
        padding-right: 100px; } }
    @media only screen and (max-width: 992px) {
      .person-modal__description {
        padding-top: 10px;
        padding-right: 0; } }
    .person-modal__description_p {
      font-size: 18px;
      font-weight: 400;
      line-height: 36px; }
      @media only screen and (max-width: 1440px) {
        .person-modal__description_p {
          font-size: 16px;
          line-height: 32px; } }
      @media only screen and (max-width: 1366px) {
        .person-modal__description_p {
          font-size: 14px;
          line-height: 28px; } }
      @media only screen and (max-width: 1200px) {
        .person-modal__description_p {
          padding-right: 60px; } }
      @media only screen and (max-width: 1024px) {
        .person-modal__description_p {
          font-size: 14px;
          line-height: 28px; } }
      @media only screen and (max-width: 576px) {
        .person-modal__description_p {
          padding-right: 0; } }
  .person-modal__form {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .person-modal__socials {
    display: flex;
    margin-top: 40px; }
  .person-modal__social svg {
    fill: white;
    margin: 0 6px;
    transition: all 0.3s ease-in-out; }
    @media only screen and (max-width: 1366px) {
      .person-modal__social svg {
        width: 26px;
        height: 26px; } }
  .person-modal__social:hover svg {
    fill: #006DFF; }
  .person-modal .modal-content {
    background-image: var(--bg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 100%; }

.service {
  display: flex;
  margin-bottom: 60px;
  align-items: center; }
  @media only screen and (max-width: 1440px) {
    .service {
      align-items: flex-start; } }
  @media only screen and (max-width: 1024px) {
    .service {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 992px) {
    .service {
      flex-direction: column;
      align-items: center;
      width: 100%; } }
  .service__icon-img {
    width: 120px; }
    @media only screen and (max-width: 1440px) {
      .service__icon-img {
        width: 100px; } }
    @media only screen and (max-width: 1024px) {
      .service__icon-img {
        width: 80px; } }
  @media only screen and (max-width: 992px) {
    .service__icon {
      margin-bottom: 20px; } }
  .service__info {
    display: flex;
    flex-direction: column;
    padding-left: 50px; }
    @media only screen and (max-width: 1024px) {
      .service__info {
        padding-left: 30px; } }
    @media only screen and (max-width: 992px) {
      .service__info {
        padding-left: 0; } }
  .service__name {
    font-size: 28px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 1440px) {
      .service__name {
        font-size: 24px;
        line-height: 22px; } }
    @media only screen and (max-width: 1024px) {
      .service__name {
        font-size: 18px;
        line-height: 20px; } }
    @media only screen and (max-width: 992px) {
      .service__name {
        text-align: center; } }
  .service__description {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 25px;
    padding-right: 140px; }
    @media only screen and (max-width: 1440px) {
      .service__description {
        padding-right: 60px; } }
    @media only screen and (max-width: 1024px) {
      .service__description {
        padding-right: 0px;
        font-size: 12px;
        line-height: 23px; } }
    @media only screen and (max-width: 992px) {
      .service__description {
        text-align: center; } }

.interested {
  background-color: #006DFF;
  width: 83%;
  margin: 0 auto;
  transform: translateY(100px);
  padding: 50px 70px 60px 70px;
  box-shadow: 0 20px 90px rgba(0, 0, 0, 0.35); }
  @media only screen and (max-width: 576px) {
    .interested {
      width: 100%;
      transform: translateY(50px);
      padding: 40px 25px; } }
  .interested__head {
    font-size: 44px;
    font-weight: 500;
    line-height: 70px;
    margin-bottom: 0px; }
  .interested__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 40px; }
  .interested__form {
    margin-top: 0; }
  .interested .form__input {
    background-color: transparent;
    border: 1px solid #ffffff;
    margin-right: 15px; }
  .interested .form__submit {
    color: #ffffff;
    display: flex;
    flex-grow: 1;
    flex-basis: 80%;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 19px;
    text-transform: uppercase;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%); }
    .interested .form__submit svg {
      height: 16px;
      margin-left: 15px; }
    .interested .form__submit:hover {
      color: #000000; }
    @media only screen and (max-width: 576px) {
      .interested .form__submit {
        width: 80%; } }
  .interested .form__checkbox {
    margin: 0;
    margin-top: 30px; }

.wrapper {
  background: #000000 url("/img/bg_dot.png") no-repeat;
  background-position: 83% -155px;
  color: #ffffff;
  font-family: Raleway; }
  @media only screen and (max-width: 1919px) {
    .wrapper {
      background-position: 83% -295px; } }
  @media only screen and (max-width: 1366px) {
    .wrapper {
      background-position: 83% -495px; } }
  @media only screen and (max-width: 1024px) {
    .wrapper {
      background-position: 83% -295px;
      background-size: 34%; } }
  @media only screen and (max-width: 992px) {
    .wrapper {
      background-position: 83% -895px;
      background-size: 51%; } }
