@mixin mq($q) {
    @media only screen and(max-width: $q * 1px) {
        @content;
    }
}

@mixin gp {
    padding-right: $global_padding;

    @include mq(992) {
        padding-right: 0;
    }
}