.form {
    margin-top: 95px;

    @include mq(1600) {
        margin-top: 75px;
    }

    @include mq(1440) {
        margin-top: 55px;
    }

    @include mq(1366) {
        margin-top: 30px;
    }

    @include mq(992) {
        width: 65%;
        
    }

    @include mq(576) {
        width: 100%;
        
    }

    &__row {
        display: flex;

        @include mq(992) {
            flex-direction: column;
        }

        &_center {
            justify-content: center;
        }
    }

    &__input {
        font-size: 18px;	
        font-weight: 500;	
        letter-spacing: 2.25px;	
        line-height: 20px;
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        border: none;
        background-color: transparent;
        color: #ffffff;
        padding: 34px 38px;
        border-radius: 0px;
        border: 1px solid #ffffff;
        margin: 0 10px;

        &::placeholder {
            opacity: 0.7;
            color: #ffffff;
        }

        @include mq(1440) {
            padding: 25px 38px;
        }

        @include mq(1366) {
            padding: 21px 25px;
            font-size: 16px;
        }

        @include mq(992) {
            width: 100%;
            margin: 0;
            margin-bottom: 10px;
        }

        @include mq(576) {
            padding: 17px 25px;
        }

        &:focus, &:active {
            outline: 0px;
        }
    }

    &__submit {
        width: 88px;
        background-color: #000000;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        height: 90px;
        margin: 0 10px;
        width: 346px;
        color: #ffffff;


        &_text {
            color: inherit;
            font-size: 16px;	
            font-weight: bold;	
            letter-spacing: 2px;	
            line-height: 19px;
            text-transform: uppercase;
            font-family: Raleway;
        }

        svg {
            width: 24px;
            height: 24px;
            transform: rotate(-90deg);
            fill: #ffffff;
            margin-left: 15px;
            height: 18px;

            &.success {
                transform: rotate(0deg);
            }

            &.hidden {
                display: none;
            }

            @include mq(1440) {
                width: 20px;
                height: 20px;
            }
            
            @include mq(576) {
                width: 16px;
                height: 16px;
            }
        }

        &_inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // padding-left: 25px;
            z-index: 2;
            background-color: transparent;
        }

        &::before {
            content: '';
            display: flex;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-110%);
            background-color: #ffffff;
            transition: all 0.2s ease-in-out;
        }

        @include mq(1440) {
            height: 72px;
        }

        @include mq(1366) {
            height: 64px;
            width: 300px;
        }

        @media only screen and (min-width: 993px) {
            &:hover {
                cursor: pointer;
                color: #000000;
        
                &::before {
                    transform: translateX(0);
                }
        
                svg {
                    fill: $main_blue;
                }
            }
        } 

        @include mq(992) {
            width: 300px;
            margin: 0;
        }

        @include mq(576) {
           height: 56px;
           width: 100%;
        }

        &:focus, &:active {
            outline: 0px;
        }
    }

    &__checkbox {
        position: relative;
        margin: 50px 10px;
        display: flex;
        align-items: center;
        &-core {
            opacity: 0;
            width: 1px;
            height: 1px;
            position: absolute;
            z-index: -1;

            &:checked + label::before {
                content: '✓';
                color: #ffffff;
            }
        }

        &-label {
            font-size: 16px;	
            font-weight: 500;	
            line-height: 26px;
            padding-left: 40px;
            margin-bottom: 0;

            @include mq(576) {
                font-size: 12px;
                line-height: 24px;
                padding-left: 30px;
            }

            &::before {
                content: '';
                display: flex;
                justify-content: center;
                align-items: center;
                width: 19px;
                height: 19px;
                border: 3px solid #ffffff;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                
            @include mq(576) {
                width: 16px;
                height: 16px;
                border-width: 2px;
            }
            }

            &:hover {
                cursor: pointer;
            }
        }

        @include mq(1366) {
            margin: 30px 10px;
        }

        
        @include mq(576) {
           margin: 10px 10px 15px 10px;
        }
    }

    &__link {
        color: #fff;
        text-decoration: underline;

        &:hover {
            color: #000000;
        }
    }
}